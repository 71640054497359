import React from 'react';
import {monthNames} from './Utils'

type Step = 'year' | 'month' | 'day' | 'hour';


interface TimeLineProps {
  selectedDate: {
    year: number;
    month: number;
    day: number;
    hour: number;
  };
  onSelectDate: (type: Step, value: number) => void;
  
}





const TimeLine: React.FC<TimeLineProps> = ({ selectedDate, onSelectDate}) => {
    const years = Array.from({ length: 9 }, (_, i) => 2017 + i); // de 2016 à 2024
    const months = Array.from({ length: 12 }, (_, i) => i + 1); // de 1 à 12
    const days = Array.from({ length: 31 }, (_, i) => i + 1); // de 1 à 31
    const hours = Array.from({ length: 24 }, (_, i) => i); // de 0 à 23

    // Fonction de rappel améliorée pour gérer les clics sur les boutons
    const handleSelectDate = (type: Step, value: number, event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault(); // Empêche le rechargement de la page
      onSelectDate(type, value);
      console.log("type : "+type+" / value : "+value);
      

      switch (type) {
        case 'year':
          selectedDate.year = value;
          break;
        case 'month':
          selectedDate.month = value;
          break;
        case 'day':
          selectedDate.day = value;
          break;
        case 'hour':
          selectedDate.hour = value;
          break;
        default:
          //TODO LOG
          break;
      }
      
      console.log("selectedDate : "+selectedDate.year+" / "+selectedDate.month+" / "+selectedDate.day+" / "+selectedDate.hour)

      //TODO OTHER event


    };

    return (
      <div>
        <div id="year-selector">
          {years.map(year => (
            <button type="button"
              key={year}
              className={`date-btn ${year === selectedDate.year ? 'selected' : ''}`}
              onClick={(event) => handleSelectDate('year', year, event)}
            >
              {year}
            </button>
          ))}
        </div>
        <div id="month-selector">
          {months.map(month => (
            <button type="button"
              key={month}
              className={`date-btn ${month === selectedDate.month ? 'selected' : ''}`}
              onClick={(event) => handleSelectDate('month', month, event)}
            >
              {monthNames[month]}
            </button>
          ))}
        </div>
        <div id="day-selector">
          {days.map(day => (
            <button type="button"
              key={day}
              className={`date-btn ${day === selectedDate.day ? 'selected' : ''}`}
              onClick={(event) => handleSelectDate('day', day, event)}
            >
              {day}
            </button>
          ))}
        </div>
        <div id="hour-selector">
          {hours.map(hour => (
            <button type="button"
              key={hour}
              className={`date-btn ${hour === selectedDate.hour ? 'selected' : ''}`}
              onClick={(event) => handleSelectDate('hour', hour, event)}
            >
              {hour}
            </button>
          ))}
        </div>
      </div>
    );
  };

export default TimeLine;