import React, { useEffect, useRef, useState } from 'react';
import * as maptalks from 'maptalks';
import { wmsDateString, getTimeFormatWMS, buildWmsUrl, min} from './Utils'
import { newVectorLayer, vectorLayerWMS, cacheVectorLayer } from './utils/VectorLayer'
import { getLayerConfigWMS, getLayerConfigEOdyn} from './utils/LayerConfig'
import {SatelliteOptionsInterface} from './SatelliteOptions';
import {LayerConfigWMS} from './types/LayerConfigWMS';
import LayerSelector from './LayerSelector';
//import { DrawTool } from 'maptalks.draw';
//const WindyLayer = require('maptalks.windylayer').WindyLayer;
import '../css/maptalks-toolbar.css';
import '../css/MapComponent.css';
import '../css/ship-info-panel.css';
//import { List } from 'echarts';
//import {WindyLayer} from "./maptalks.windylayer.js";
import LayerCache from './LayerCache';

const { FeatureLayerService } = require('maptalks.esri');


interface MapProps {
  center: [number, number];
  zoom: number;
  isOverviewVisible: boolean; // Ajouter cette prop pour contrôler la visibilité de l'aperçu de la carte
  selectedDate: Date;
  isDrawingMenuVisible: boolean;
  toggleCapturingMap: boolean;
}


const MapComponent: React.FC<MapProps & 
    { visibleLayers: string[] } & 
    { visibleSatelite: SatelliteOptionsInterface[] } & 
    { layerOrderChange: any}  & 
    { isDrawingMenuVisible: any } &
    { toggleCapturingMap: any } &
    { handleGridOnChange: any } &
    { handleReferenceOnChange: any } &
    { isDrawingTargetVisible: any } &
    { isSliderVisible: any } &
    { visibleLayersSlider: string[] }
  > = (
  { 
    center, 
    zoom, 
    isOverviewVisible, 
    visibleLayers, 
    selectedDate, 
    visibleSatelite, 
    layerOrderChange,
    isDrawingMenuVisible, // Prop pour activer/désactiver le mode dessin
    toggleCapturingMap,
    handleGridOnChange,
    handleReferenceOnChange,
    isDrawingTargetVisible,
    isSliderVisible,
    visibleLayersSlider
  }) => { 

  const mapContainer = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<maptalks.Map | null>(null);
  const [overviewControl, setOverviewControl] = useState<maptalks.control.Overview | null>(null);
  const [drawingTool, setDrawingTool] = useState(null); // État pour le contrôle de dessin

  const [zoomLevel, setZoomLevel] = useState(10);
  // Utiliser useRef pour garder une trace de l'ancienne valeur de la date
  const prevDateRef = useRef<Date>();

  const prevLayerOderRef = useRef<Object>();
  const layerCache = useRef(new LayerCache());

  const preVisibleLayers = useRef<string[]>([]);
  const preVisibleLayersSlider = useRef<string[]>([]);

  //const [drawingTool, setDrawingTool] = useState<maptalks.DrawTool | null>(null);
  // Initialisation de la carte
  useEffect(() => {
    if (!mapContainer.current) return; // Sortie précoce si le conteneur n'est pas encore disponible

      const initialMap = new maptalks.Map(mapContainer.current, {
        center: new maptalks.Coordinate(center), // Conversion du tuple en Coordinate
        zoom: zoom,
        maxZoom: 18,
        //minZoom: 4,
        dragPitch : false,
        dragRotate : false,
        //zoomControl: {
        //  'position'  : 'top-left',
        //  'zoomLevel' : true
        //},
        //overviewControl: true,
        //spatialReference: {
        //  projection: 'EPSG:4326'
        //},
        //baseLayer: new maptalks.WMSTileLayer('wms', {
        //  'tileSystem': [1, -1, -180, 90],
        //  'urlTemplate': 'https://ows.terrestris.de/osm/service',
        //  'crs': 'EPSG:4326',
        //  'layers': 'OSM-WMS',
        //  'styles': '',
        //  'version': '1.3.0',
        //  'format': 'image/png',
        //  'transparent': true,
        //  'uppercase': true
        //}),
        baseLayer: new maptalks.TileLayer('map', {
          // TODO CORS CROSS ORIGN for orther map
          //tileSystem: [1, -1, -180, 90],
          urlTemplate: "https://fly.maptiles.arcgis.com/arcgis/rest/services/World_Imagery_Firefly/MapServer/tile/{z}/{y}/{x}",//'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          subdomains: ['a', 'b', 'c'],
          //repeatWorld: false,
          
        }),
    });

    // Après la création de la carte, restreignez le panoramique à une certaine étendue
    //initialMap.on('moveend', () => {
    //  const x = 30
    //  const extent = new maptalks.Extent([-180+x, -80+x, 180-x, 80-x]);
    //  // Ajustez si nécessaire pour correspondre à votre utilisation
    //  if (!extent.contains(initialMap.getCenter())) {
    //    const center = initialMap.getCenter().toArray();
    //    const correctedCenter = new maptalks.Coordinate(
    //      Math.max(Math.min(center[0], extent.xmax), extent.xmin),
    //      Math.max(Math.min(center[1], extent.ymax), extent.ymin)
    //    );
    //    initialMap.panTo(correctedCenter);
    //  }
    //});


    //var noZoomLevel = new maptalks.control.Zoom({
    //  'position'  : 'top-right',
    //  'zoomLevel' : false
    //});
    //initialMap.addControl(noZoomLevel);
    //
    //var noSlider = new maptalks.control.Zoom({
    //  'position'  : 'bottom-right',
    //  'zoomLevel' : true
    //});
    //initialMap.addControl(noSlider);
    //
    //var customPosition = new maptalks.control.Zoom({
    //  'position'  : { 'bottom' : '20', 'left' : '20' },
    //  'zoomLevel' : false
    //});
    //initialMap.addControl(customPosition);
    setMap(initialMap);

    // Créer l'aperçu de la carte et le stocker dans l'état
    const overview = new maptalks.control.Overview({
      position: 'bottom-right',
      size: [200, 147],
      maximize: true,
      level: 25,
      symbol: {
        'lineWidth': 1,
        'lineColor': '#1bbc9b',
        'polygonFill': '#1bbc9b',
        'polygonOpacity': 0.2,
      },
      containerClass: 'maptalks-overview',
    });
    overview.addTo(initialMap);
    setOverviewControl(overview);


    const handleZoomChange = () => {
      const newZoom = initialMap.getZoom();
      //console.log('Zoom level changed to:', newZoom);
      setZoomLevel(newZoom);
    };

    initialMap.on('zoomend', handleZoomChange);

    

    return () => {
      initialMap.remove();
    };
  }, []); // Ce useEffect ne s'exécute qu'une seule fois lors du montage du composant



  // Mise à jour du centre de la carte
  useEffect(() => {
    if (map) {
      map.setCenter(new maptalks.Coordinate(center)); // Conversion du tuple en Coordinate
    }
  }, [center, map]); // Ce useEffect s'exécute lorsque `center` ou `map` change

  // Mise à jour du zoom de la carte
  //useEffect(() => {
  //  if (map) {
  //    map.setZoom(zoom);
  //  }
  //}, [zoom, map]); // Ce useEffect s'exécute lorsque `zoom` ou `map` change

  useEffect(() => {
    if (!map) return;
    console.log("Nouveau niveau de zoom:", map.getZoom());
  }, [map, zoomLevel]); // Ce useEffect s'exécute lorsque `zoom` ou `map` change

  useEffect(() => {
    if (!map) return; // Assurez-vous que map est défini
    // Gestionnaire d'événements de zoom
    const handleZoom = () => {
      const newZoom = map.getZoom(); // Obtenez le nouveau niveau de zoom de votre instance de carte
      map.setZoom(newZoom); // Mettez à jour l'état mapZoom
      console.log("Nouveau niveau de zoom:", newZoom);
    };
  
    // Abonnez-vous à l'événement de zoom
    map.on('zoom', handleZoom); // 'zoom' est un exemple, ajustez selon la bibliothèque que vous utilisez
  
    // Nettoyage de l'effet
    return () => {
      map.off('zoom', handleZoom); // Désabonnez-vous de l'événement de zoom
    };
  }, [map]); // Incluez d'autres dépendances si nécessaire, par exemple mapZoom si vous souhaitez réagir à ses changements
  

  useEffect(() => {
    // Utiliser la prop isOverviewVisible pour afficher ou masquer l'aperçu
    if (overviewControl) {
      isOverviewVisible ? overviewControl.show() : overviewControl.hide();
    }
  }, [isOverviewVisible, overviewControl]);


  function getLayersOrder(): string[] {
    return layerOrderChange.map((layer: LayerConfigWMS) => layer.id);
  }


  // Gestion de l'ajout des layers : visibleLayers
  useEffect(() => {
    if (!map) return;

    let mapLayers = map.getLayers()
    let mapLayersId = mapLayers.map(layer => layer.getId());
    const layersOrderChange: string[] = getLayersOrder();

    visibleLayers.forEach(visibleId => {
      if (visibleLayersSlider.includes(visibleId)){
        // layer visibleId est deja affiché sur les layers du slider
        console.log("Can't add layer twice : "+visibleId)
        errorMessage("Can't add layer twice : "+visibleId);
      } else if (!mapLayersId.includes(visibleId)){
        // layer visibleId doit être ajouté a la carte
        // La couche doit être visible mais n'est pas encore sur la carte, donc ajoutez-la
        if (visibleId.includes("wms")){
          vectorLayerWMS(layerCache.current, map, visibleId, selectedDate, 1000-layersOrderChange.indexOf(visibleId))
          const layer_config = getLayerConfigWMS(visibleId);
          if (layer_config !== null && layer_config !== undefined) {
            const legendUrl = layer_config.urlTemplate+'?SERVICE=WMS&REQUEST=GetLegendGraphic&VERSION='+layer_config.version+'&FORMAT=image/png&LAYER='+layer_config.layers;
            var d = document.getElementById('legend-img') as HTMLImageElement;
            if (d) {
              console.log("legendUrl : "+legendUrl)
              d.src = legendUrl;
            }
          }
        } else {
          // For other layers
          newVectorLayer(layerCache.current, map, visibleId, selectedDate);
        }
      } else {
         // layer visibleId doit est déjà ajouté à la carte
        console.log("Le layer : ",visibleId, " est déjà ajouté à la carte");
      }

    });

    console.log("Size visibleLayers=",visibleLayers.length)
    console.log("Size preVisibleLayers=",preVisibleLayers.current.length)
    // Gérer les suppréssion de layers
    if (preVisibleLayers.current && visibleLayers.length !== preVisibleLayers.current.length){
      preVisibleLayers.current.forEach((id: string) => {
        if (!visibleLayers.includes(id)) {
          map.removeLayer(id);
          console.log("Le layer ",id, "est supprimé")
        }
      });
    }

    preVisibleLayers.current = visibleLayers;
    
}, [map, visibleLayers]); // Réagir aux changements dans 'visibleLayers'


function updateLayers(layers: string[], index: number) {
  if (!map) return;
  const layersOrder: string[] = getLayersOrder();

  layers.forEach((id: string) => {
    if (id.includes("wms")){
      map.removeLayer(id);
      console.log("REMOVE wms layer id : "+id);
      
      vectorLayerWMS(layerCache.current ,map, id, selectedDate, index-layersOrder.indexOf(id))
      console.log("INDEX wms layer id= ",id," index=",layersOrder.indexOf(id))
      
      if (visibleLayersSlider.includes(id)) {
        sliderRendering(map.getLayer(id))
      }
      console.log("Update layer id : "+id);

    } else {
      // Other data
      newVectorLayer(layerCache.current, map, id, selectedDate)

      if (isSliderVisible) {
        toggleDefaultLayer(true)
        //sliderRendering(map.getLayer(layerId))
      }
      console.log("UPDATE layer id : "+id);
    }
  });
  
}

// Mise à jour des layers avec la date
useEffect(() => {
  if (!map) return;

  if (prevDateRef.current &&
    prevDateRef.current.getFullYear() === selectedDate.getFullYear() &&
    prevDateRef.current.getMonth() === selectedDate.getMonth() &&
    prevDateRef.current.getDate() === selectedDate.getDate() &&
    prevDateRef.current.getHours() === selectedDate.getHours() &&
    prevDateRef.current.getMinutes() === selectedDate.getMinutes() &&
    prevDateRef.current.getSeconds() === selectedDate.getSeconds() //&&
  ) return;

  prevDateRef.current = selectedDate;
  console.log("PREV_DATE CHANGE : "+
  prevDateRef.current.getFullYear()+"/"+
  prevDateRef.current.getMonth()+"/"+
  prevDateRef.current.getDate()+"_"+
  prevDateRef.current.getHours()+":"+
  prevDateRef.current.getMinutes()+":"+
  prevDateRef.current.getSeconds()
)

  console.log("DATE CHANGE : "+
    selectedDate.getFullYear()+"/"+
    selectedDate.getMonth()+"/"+
    selectedDate.getDate()+"_"+
    selectedDate.getHours()+":"+
    selectedDate.getMinutes()+":"+
    selectedDate.getSeconds()
  )

  
  updateLayers(visibleLayers,1000)
  updateLayers(visibleLayersSlider,3000)

}, [map, selectedDate]);


// Change map background
useEffect(() => {
  if (!map || !visibleSatelite.length) return;

  console.log("visibleSatelite : ", visibleSatelite);

  map.setBaseLayer(new maptalks.TileLayer('map',{
    urlTemplate: visibleSatelite[0].url,
    subdomains: visibleSatelite[0].subdomains
  }));

}, [map, visibleSatelite]);


function inActiveLayer(id: string, layers: maptalks.Layer[]) {
  var res: boolean = false;
  layers.forEach(layer => {
    //console.log("TEST::",layer.getId(),"==",id)
    if (layer.getId() === id) res=true;
  });
  return res;
}

// order layer change
useEffect(() => {
  if (!map) return;
  let activeLayers = map.getLayers()
  if (activeLayers.length === 0) return;

  console.log("Order layer change : ",layerOrderChange)

  const newOrderLayer: LayerConfigWMS[] = layerOrderChange;

  layerOrderChange.forEach((layer: LayerConfigWMS)  => {
    if (inActiveLayer(layer.id, activeLayers)){
      const layerChange = map.getLayer(layer.id)
      if (visibleLayersSlider.includes(layer.id)){
        layerChange.setZIndex(4000-newOrderLayer.indexOf(layer))
        sliderRendering(map.getLayer(layer.id))
        console.log("visibleLayersSlider update order of id : ",layer.id)
      } else if (visibleLayers.includes(layer.id)){
        layerChange.setZIndex(1000-newOrderLayer.indexOf(layer))
        console.log("visibleLayers update order of id : ",layer.id)
      } else {
        console.warn("Unknown layer id : ",layer.id)
      }
      
    }
  });

  prevLayerOderRef.current = layerOrderChange;
  
}, [map, layerOrderChange]);

 // Écouteur pour activer/désactiver le dessin
 useEffect(() => {
  if (!map) return;


  if (map.getLayer("draw")) return;

 
  var layer = new maptalks.VectorLayer('draw').setZIndex(5000).setOpacity(0.8).addTo(map);



  //if (isDrawingMenuVisible) {

    console.log("isDrawingMenuVisible : true")
    var drawTool = new maptalks.DrawTool({
      mode: 'Point'
    }).addTo(map).disable();

    drawTool.on('drawend', function (param) {
      console.log(param.geometry);
      layer.addGeometry(param.geometry);
      drawTool.disable();
      
    });

    var items = ['Point', 'LineString', 'Polygon', 'Circle', 'Ellipse', 'Rectangle', 'FreeHandLineString', 'FreeHandPolygon'].map(function (value) {
      return {
        item: '<span class="custom-class-item">'+value+'</span>',
        click: function () {
          drawTool.setMode(value).enable();
          console.log(value)
        }
      };
    });

    var toolbar = new maptalks.control.Toolbar({
      items: [
        {
          item: "Shape",
          children: items
        },
        {
          item: "Enable",
          click: function () {
            drawTool.enable();
            layer.addTo(map);
          }
        },
        {
          item: "Disable",
          click: function () {
            drawTool.disable();
            layer.remove();
          }
        },
        {
          item: "Clear",
          click: function () {
            layer.clear();
          }
        },
        {
          item: "Close",
          click: function () {
            layer.remove()
            //layerOrderChange = [layer];
            toolbar.remove()
            drawTool.remove()
          }
        }
      ]
    }).addTo(map);

    console.log("toolbar : "+toolbar);

    console.log(map.getMenuItems())

  //} else {
  //  console.log(map.getPanels())
    //map.removeLayer('draw');
    //var toolbar = new maptalks.control.Toolbar({
    //  items: []
    //}).addTo(map).update();

    //console.log(toolbar.show())
    
    
    //console.log(map.getLayers())
  //  drawingTool.disable();
  //console.log("isDrawingMenuVisible : false")
  //}
}, [isDrawingMenuVisible, drawingTool]);

// Map capturing image
useEffect(() => {
  if (!map) return;
  map.toDataURL({
    'mimeType' : 'image/jpeg', // or 'image/png'
    'save' : true,             // to pop a save dialog
    'fileName' : 'map'         // file name
  });

}, [toggleCapturingMap]);


function query(map: maptalks.Map) {
  const layerName = "gridLayer";

  // Récupérer et supprimer la couche existante si elle est déjà présente
  const existingLayer = map.getLayer(layerName);
  if (existingLayer) {
    map.removeLayer(existingLayer);
  }

  // Créer une nouvelle couche vectorielle pour la grille
  const gridLayer = new maptalks.VectorLayer(layerName).addTo(map);
  gridLayer.setZIndex(10001); // Définir l'index de z après avoir ajouté la couche à la carte

  const extent = map.getExtent();
  const geometry = [extent.xmin, extent.ymin, extent.xmax, extent.ymax].join(',');

  const service = new FeatureLayerService({
    url: 'https://services.arcgis.com/nGt4QxSblgDfeJn9/ArcGIS/rest/services/Firefly_Grid/FeatureServer/1',
    visible: false
  });

  // Styles de ligne en fonction de la valeur 'Scale'
  const lineStyles: { [key: string]: { lineColor: string; lineWidth: number; lineDash?: number[] } } = {
    '0': { lineColor: 'rgba(0,172,230,0.6)', lineWidth: 2, lineDash: [4, 4] },
    '1': { lineColor: 'rgba(0,172,230,0.15)', lineWidth: 1 },
    '10': { lineColor: 'rgba(0,172,230,0.45)', lineWidth: 1 },
    '20': { lineColor: 'rgba(0,172,230,0.6)', lineWidth: 1 },
    '5': { lineColor: 'rgba(0,172,230,0.35)', lineWidth: 1 }
  };

  service.query({
    geometry: geometry,
    geometryType: 'esriGeometryEnvelope',
    gridQuery: true
  }).then((json: any) => {
    json = JSON.parse(json);
    console.log('features count:', json.features.length);
    const geometries = service.toGeometry(json);

    geometries.forEach((geometry: any) => {
      const scaleValue = geometry.getProperties().Scale;
      const style = lineStyles[scaleValue] || { lineColor: '#000', lineWidth: 1 };
      geometry.setSymbol(style);
    });

    gridLayer.clear(); // Clear existing geometries
    gridLayer.addGeometry(geometries); // Add new geometries
  });
}

// Grid and References
useEffect(() => {
  if (!map) return;

  const referencesLayerName = "referencesLayer";
  const gridLayerName = "gridLayer";

  let referencesLayer = map.getLayer(referencesLayerName) as maptalks.TileLayer;
  let gridLayer = map.getLayer(gridLayerName) as maptalks.VectorLayer;

  // Fonction pour actualiser la grille
  const updateGrid = () => {
    if (handleGridOnChange) {
      query(map);
    }
  };

  // Gestion du calque de référence
  if (handleReferenceOnChange) {
    console.log("handleReferenceOnChange: ON");

    if (!referencesLayer) {
      referencesLayer = new maptalks.TileLayer(referencesLayerName, {
        urlTemplate: 'https://server.arcgisonline.com/arcgis/rest/services/Ocean/World_Ocean_Reference/MapServer/tile/{z}/{y}/{x}',
        subdomains: ["a", "b", "c", "d"],
        cssFilter: 'grayscale(100%) brightness(400%)',
        visible: true
      }).setZIndex(10000);

      map.addLayer(referencesLayer);
    } else {
      referencesLayer.show();  // Si déjà ajouté, simplement afficher
    }

    // Assurer que le calque de référence est en dessous du calque de grille
    if (gridLayer) {
      referencesLayer.bringToBack();
      gridLayer.bringToFront();
    }

  } else {
    console.log("handleReferenceOnChange: OFF");
    if (referencesLayer) {
      referencesLayer.hide();  // Utiliser hide() pour éviter les conflits
    }
  }

  // Gestion du calque de grille
  if (handleGridOnChange) {
    console.log("handleGridOnChange: ON");

    // Exécuter la fonction query pour charger les données de la grille
    updateGrid();

    // Assurer que le calque de grille est au-dessus du calque de référence
    if (referencesLayer) {
      gridLayer = map.getLayer(gridLayerName) as maptalks.VectorLayer; // Récupérer la nouvelle instance
      if (gridLayer) {
        gridLayer.bringToFront();
      }
    }

  } else {
    console.log("handleGridOnChange: OFF");
    if (gridLayer) {
      gridLayer.hide();  // Utiliser hide() pour éviter les conflits
    }
  }

  // Réagir aux changements de zoom et de déplacement pour corriger les problèmes d'affichage
  const handleZoomOrMove = () => {
    if (handleGridOnChange) {
      updateGrid();
    }
  };

  map.on('zoomend', handleZoomOrMove);
  map.on('moveend', handleZoomOrMove);

  // Nettoyage des écouteurs d'événements lorsque le composant est démonté ou que les dépendances changent
  return () => {
    map.off('zoomend', handleZoomOrMove);
    map.off('moveend', handleZoomOrMove);
  };

}, [handleReferenceOnChange, handleGridOnChange, map]);

// opendrift menu
useEffect(() => {
  if (!map) return;


  if (map.getLayer("target")) return;

 
  var layer = new maptalks.VectorLayer('target').setZIndex(2000).setOpacity(0.8);

  layer.setStyle([
    {
      'symbol': [
        {
          'polygonFill': "#1bbc9b",
          'polygonOpacity': 0.5,
          'lineColor': '#000',
          'lineWidth': 2
        },
        {
          'textName' : '{count}',
          'textSize' : 40,
          'textFill' : '#fff'
        }
      ]
    },
  ]);

  layer.addTo(map);

  //if (isDrawingMenuVisible) {

    console.log("isDrawingMenuVisible : true")
    var drawTool = new maptalks.DrawTool({
      mode: 'Circle'
    }).addTo(map).disable();

    drawTool.on('drawend', function (param) {
      console.log(param.geometry);
      layer.addGeometry(param.geometry);
      drawTool.disable();
      //console.log(layer.getLastGeometry().getCenter()["x"]);
      //console.log(layer.getLastGeometry().getCenter()["y"]);
      //console.log(layer.getLastGeometry().toJSON()["radius"]/1e4);
    });


    var toolbar = new maptalks.control.Toolbar({
      items: [
        {
          item: "Target",
          click: function () {
            layer.clear();
            drawTool.setMode('Circle').enable();
            console.log('Circle');
          }
        },
        {
          item: "Clear",
          click: function () {
            layer.clear();
          }
        },
        {
          item: "Close",
          click: function () {
            layer.remove()
            //layerOrderChange = [layer];
            toolbar.remove()
            drawTool.remove()
          }
        }
      ]
    }).addTo(map);

    console.log("toolbar : "+toolbar);

    console.log(map.getMenuItems())



  //} else {
  //  console.log(map.getPanels())
    //map.removeLayer('draw');
    //var toolbar = new maptalks.control.Toolbar({
    //  items: []
    //}).addTo(map).update();

    //console.log(toolbar.show())
    
    
    //console.log(map.getLayers())
  //  drawingTool.disable();
  //console.log("isDrawingMenuVisible : false")
  //}
}, [isDrawingTargetVisible, drawingTool]);


function sliderRendering(layer: maptalks.Layer) {
  if (!mapContainer.current) return;
  if (!map) return;
  var swipe = document.getElementById('myRange') as HTMLInputElement;
  
  if (!swipe) return;
  var renderer = layer.getRenderer();
  var canvasGetter = renderer.getCanvasImage;
  var swipeCanvas = document.createElement('canvas');
  console.log(`Canvas width: ${swipeCanvas.width}, Canvas height: ${swipeCanvas.height}`);
  //override renderer's default method to get layer canvas image
  renderer.getCanvasImage = function () {
    //var dpr = map.getDevicePixelRatio();
    //original layer canvas image
    var layerImage = canvasGetter.call(renderer);
    if (!layerImage || !layerImage.image) {
      return layerImage;
    }
    //drawn width after layer is erased by swipper
    var ctx = renderer.context;

    var width = renderer.canvas.width * (Number(swipe.value) / 100);
    var height = ctx.canvas.height;

    var leftCtx = swipeCanvas.getContext('2d');
    if (!leftCtx)return;

    swipeCanvas.width = ctx.canvas.width;
    swipeCanvas.height = ctx.canvas.height;
    leftCtx.clearRect(0, 0, swipeCanvas.width, swipeCanvas.height);
    leftCtx.drawImage(layerImage.image, 0, 0, width, height, 0, 0, width, height);
    layerImage.image = swipeCanvas;
    //console.log("drawImage")
    return layerImage;
  };

  swipe.addEventListener('input', function () {
    //let layer redraw self in the next frame
    //layer.getRenderer().setToRedraw();
    if (layer.getRenderer()) {
      //console.log(`Renderer Canvas width: ${renderer.canvas.width}, height: ${renderer.canvas.height}`);
      //console.log(`Swipe value: ${swipe.value}`);
      //console.log('Redrawing layer');
      layer.getRenderer().setToRedraw();
    }
  });
}

  // Gestion of default slider layer
  const toggleDefaultLayer = (show: boolean) => {
    if (!mapContainer.current) return;
    if (!map) return;

    const defaultLayer = map.getLayer("slider") || new maptalks.TileLayer('slider', {
      urlTemplate: 'https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}',
      subdomains: ['a', 'b', 'c'],
      visible: true,
      opacity: 1,
      //zindex: 100,
    });

    if (!map.getLayer("slider")) {
      map.addLayer(defaultLayer.setZIndex(2000));
      console.log("ADD default slider layer")
    }
    show ? defaultLayer.show() : defaultLayer.hide();
    sliderRendering(map.getLayer("slider"));
  };

  const errorMessage = (msg: string) => {
    if (!map) return;
    // Créer le panneau avec des options de positionnement
    const textPanel = new maptalks.control.Panel({
      //position: 'absolute', // Utilisez 'absolute' pour le positionnement CSS
      //draggable: true,
      
      content: `<div class=error-message-panel>${msg}</div>`,
      closeButton: true,
    }).addTo(map);
  
    // Assurez-vous que le panneau est ajouté à la carte
    //map.addControl(textPanel);
  };


  // Gestion de l'ajout des layers : visibleLayersSlider
  useEffect(() => {
    if (!map || !mapContainer.current) return;
    if (!isSliderVisible) {
      console.log("Le slider est caché")
      toggleDefaultLayer(false);
      console.log(visibleLayersSlider)
      console.log(preVisibleLayersSlider)
      preVisibleLayersSlider.current.forEach((id: string) => {
        map.removeLayer(id);
        console.log("Le layer slider ",id, "est supprimé")
      });
      return;
    }

    toggleDefaultLayer(true);
    console.log("ZERO layer");

    let mapLayers = map.getLayers()
    let mapLayersId = mapLayers.map(layer => layer.getId());
    const layersOrderChange: string[] = getLayersOrder();

    visibleLayersSlider.forEach(visibleId => {
      if (visibleLayers.includes(visibleId)){
        // layer visibleId est deja affiché sur les layers du slider
        console.log("Can't add layer slider twice : "+visibleId)
        errorMessage("Can't add layer slider twice : "+visibleId);
      } else if (!mapLayersId.includes(visibleId)){
        // layer visibleId doit être ajouté a la carte
        // La couche doit être visible mais n'est pas encore sur la carte, donc ajoutez-la
        if (visibleId.includes("wms")){
          vectorLayerWMS(layerCache.current, map, visibleId, selectedDate, 3000-layersOrderChange.indexOf(visibleId))
          const layer_config = getLayerConfigWMS(visibleId);
          if (layer_config !== null && layer_config !== undefined) {
            const legendUrl = layer_config.urlTemplate+'?SERVICE=WMS&REQUEST=GetLegendGraphic&VERSION='+layer_config.version+'&FORMAT=image/png&LAYER='+layer_config.layers;
            var d = document.getElementById('legend-img') as HTMLImageElement;
            if (d) {
              console.log("legendUrl : "+legendUrl)
              d.src = legendUrl;
            }
          }
        } else {
          // For other layers
          newVectorLayer(layerCache.current, map, visibleId, selectedDate);
        }
        // Rendering 
        
      } else {
         // layer visibleId doit est déjà ajouté à la carte
        console.log("Le layer slider : ",visibleId, " est déjà ajouté à la carte");
      }

    });

    visibleLayersSlider.forEach((element: string) => {
      console.log("sliderRendering : ",element)
      sliderRendering(map.getLayer(element))
    });

    console.log("Size visibleLayersSlider=",visibleLayers.length)
    console.log("Size preVisibleLayersSlider=",preVisibleLayers.current.length)
    // Gérer les suppréssion de layers
    if (preVisibleLayersSlider.current && visibleLayersSlider.length !== preVisibleLayersSlider.current.length){
      preVisibleLayersSlider.current.forEach((id: string) => {
        if (!visibleLayersSlider.includes(id)) {
          map.removeLayer(id);
          console.log("Le layer slider ",id, "est supprimé")
        }
      });
    }

    preVisibleLayersSlider.current = visibleLayersSlider;

  }, [map, visibleLayersSlider, isSliderVisible]);

  function addInfoPanel(map: maptalks.Map,content: string, properties: any) {
    // Vérifiez si un panneau existe déjà pour éviter d'en ajouter plusieurs
    //const panels = map.
    //if (existingPanel) {
    //  existingPanel.setContent(content); // Mettre à jour le contenu si le panneau existe
    //  return;
    //}
    const panelId = properties?'info-panel_'+properties.ship_id:"infoPanelError"
    console.log(panelId)
    const existingPanel = map.getLayer(panelId)?.options.control;
    console.log("existe ? ",map.getLayer(panelId))
    if (existingPanel) {
      map.removeLayer(panelId)
    }



    // Ajouter un nouveau panneau
    const textPanel = new maptalks.control.Panel({
      position: 'top-left', // Position du panneau
      draggable: true, // Panneau déplaçable
      custom: false,
      content: content,
      closeButton: true,
      //id: 'ship-info-panel', // Identifiant pour le panneau
    }).addTo(map);
  }
  
  // Gestion des clics sur la carte
  useEffect(() => {
    if (!map) return;
    //if (!map.getLayer("markersLayer")) return;
    //if (!map.getLayer("wms_ship")) return;
    map.on('click', async function (param) {
      if (!map.getLayer("wms_ship") && !map.getLayer("markersLayer")) return;
      console.log('Coordonnées géographiques cliquées :', param.coordinate.toArray());
      const timeformat = prevDateRef.current?.toISOString().split('T')[0].replaceAll("-","");

      const extent = map.getExtent();
      const size = map.getSize();
      const containerPoint = map.coordinateToContainerPoint(param.coordinate); // Coordonnées en pixels
  
      // Calcul des coordonnées X et Y en pixels
      const x = Math.round(containerPoint.x); // X en pixels
      const y = Math.round(containerPoint.y); // Y en pixels
      console.log('Coordonnées en pixels :', x, y);
  
      // Définir le Bounding Box (BBOX)
      const bbox = `${extent.xmin},${extent.ymin},${extent.xmax},${extent.ymax}`;
  
      // URL de la requête GetFeatureInfo
      const url = `https://geo.eodyn.com/geoserver/eodyn/wms?service=WMS&version=1.1.1&request=GetFeatureInfo` +
        `&layers=eodyn:${timeformat}_ship_positions&query_layers=eodyn:${timeformat}_ship_positions` +
        `&styles=&bbox=${bbox}&width=${size.width}&height=${size.height}&srs=EPSG:4326` +
        `&info_format=application/json&x=${x}&y=${y}&buffer=100`;
  
      console.log('URL de la requête :', url);
  
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Erreur HTTP ${response.status}`);
        }
        const data = await response.json();
  
        // Vérifiez si des entités sont retournées
        if (data.features && data.features.length > 0) {
          const properties = data.features[0].properties;
          const content = `
            <div class="ship-info-panel">
              <h3 class="ship-info-h">Ship Informations</h3>
              <p class="ship-info-p"><strong>Ship ID:</strong> ${properties.ship_id}</p>
              <p class="ship-info-p"><strong>COG:</strong> ${properties.cog}</p>
              <p class="ship-info-p"><strong>SOG:</strong> ${properties.sog}</p>
            </div>
          `;
          addInfoPanel(map, content, properties); // Ajouter ou mettre à jour le panneau d'information
        } else {
          //addInfoPanel(map, '<div>Aucune entité trouvée à cet emplacement.</div>', null);
          console.log("Ship::data:: Aucune entité trouvée à cet emplacement")
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
        //addInfoPanel(map, '<div>Erreur lors de la récupération des données.</div>');
      }
    });
  
    // Nettoyer l'écouteur d'événement lors du démontage
    return () => {
      //map.off('click');
    };
  }, [map]);
  

  return (
    <div>
      <div 
        ref={mapContainer} 
        className="maptalk-wrapper" 
      />
      {/*
      <div id="legend"
      style={{
        position: "absolute", 
        bottom: "10px", 
        left: "10px", 
        background: "white", 
        padding: "10px", 
        border: "1px solid black" 
      }}>
      <img id="legend-img" src="" alt="Legend"
      style={{ 
        maxWidth: "200px", // Set your max width
        maxHeight: "200px", // Set your max height
        position: "absolute", 
        bottom: "10px", 
        left: "10px", 
        background: "white", 
        padding: "10px", 
        border: "1px solid black" 
      }}
      />
      </div>
      */}
    </div>
  );
};

export default MapComponent;
